/*!

=========================================================
* Material Dashboard React - v1.9.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/material-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// @material-ui/icons
import DashboardIcon from "@material-ui/icons/Dashboard";
import BoardsIcon from "@material-ui/icons/Memory";
//import Camera from "@material-ui/icons/Camera";
/* import LibraryBooks from "@material-ui/icons/LibraryBooks";
import BubbleChart from "@material-ui/icons/BubbleChart";
import LocationOn from "@material-ui/icons/LocationOn";
import Notifications from "@material-ui/icons/Notifications"; */
//import Unarchive from "@material-ui/icons/Unarchive";
//import Language from "@material-ui/icons/Language";

// core components/views for Admin layout
//import DashboardPage from "views/Dashboard/Dashboard.js";
import Dashboard from "views/Dashboard/Dashboard";
import Boards from "views/Boards/Boards";
import Payments from "views/Payments/Payments";

//Lockyt
import LockytBoards from "views/Lockyt/Boards/Boards";

/* import UserProfile from "views/UserProfile/UserProfile.js";
import TableList from "views/TableList/TableList.js";
import Typography from "views/Typography/Typography.js";
import Icons from "views/Icons/Icons.js"; */
//import Maps from "views/Maps/Maps.js";
//import NotificationsPage from "views/Notifications/Notifications.js";
//import Payment from "views/Payment/Payment.js";
//import UpgradeToPro from "views/UpgradeToPro/UpgradeToPro.js";
// core components/views for RTL layout
//import RTLPage from "views/RTLPage/RTLPage.js";

const dashboardRoutes = [
  {
    path: "/dashboard",
    name: "Status",
    //rtlName: "لوحة القيادة",
    icon: DashboardIcon,
    component: Dashboard,
    layout: "/admin",
  },
  {
    path: "/boards",
    name: "Placas",
    //rtlName: "لوحة القيادة",
    icon: BoardsIcon,
    component: Boards,
    layout: "/admin",
  },
  {
    path: "/payments",
    name: "Pagamentos",
    //rtlName: "لوحة القيادة",
    icon: "credit_card",
    component: Payments,
    layout: "/admin",
  },
  {
    path: "/lockyt/boards",
    name: "Placas Lockyt",
    //rtlName: "لوحة القيادة",
    icon: BoardsIcon,
    component: LockytBoards,
    layout: "/admin",
  },
];

export default dashboardRoutes;
