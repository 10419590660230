import React, { useState, useEffect } from "react";

// @material-ui/core
import {
  Paper,
  Typography,
  Grid,
  IconButton,
  LinearProgress,
} from "@material-ui/core";
//import { makeStyles } from "@material-ui/core/styles";

import moment from "moment";

//import axios from "config/axios";

// @material-ui/icons
import ErrorIcon from "@material-ui/icons/Error";
import CloseIcon from "@material-ui/icons/Close";
import InfoIcon from "@material-ui/icons/Info";
import ReplayIcon from "@material-ui/icons/Replay";

// custom components
//import GridItem from "components/Grid/GridItem.js";
//import GridContainer from "components/Grid/GridContainer.js";
import Snackbar from "components/Snackbar/Snackbar.js";

import axios from "config/axios";

//import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";

//const useStyles = makeStyles(styles);
import useStyles from "./styles";

export default function Dashboard() {
  const [alert, setAlert] = useState(""),
    [alertSuccess, setAlertSuccess] = useState(""),
    [loading, setLoading] = useState(false),
    [restarts, setRestarts] = useState([]);

  const classes = useStyles();

  const getAlertRestart = async () => {
    setLoading((oldState) => !oldState);
    axios
      .get("/admin/restarts/false")
      .then(({ data }) => {
        setLoading((oldState) => !oldState);
        setRestarts(data);
      })
      .catch((error) => {
        setLoading((oldState) => !oldState);
        console.log(error);
        setAlert("Problema ao carregar");
      });
  };

  useEffect(() => {
    getAlertRestart();
    const interval = setInterval(() => {
      getAlertRestart();
    }, 720000);
    return () => clearInterval(interval);
  }, []);

  const expireAlert = async (id) => {
    setLoading((oldState) => !oldState);
    axios
      .put("/admin/restart", {
        id,
        expired: true,
      })
      .then(() => {
        setLoading((oldState) => !oldState);
        getAlertRestart();
        setAlertSuccess("Alerta removido");
      })
      .catch((error) => {
        setLoading((oldState) => !oldState);
        console.log(error);
      });
  };

  return (
    <div>
      <Snackbar
        place="tc"
        color="danger"
        icon={ErrorIcon}
        message={alert}
        open={alert ? true : false}
        closeNotification={() => setAlert("")}
        close
      />
      <Snackbar
        place="tc"
        color="success"
        icon={InfoIcon}
        message={alertSuccess}
        open={alertSuccess ? true : false}
        closeNotification={() => setAlertSuccess("")}
        close
      />
      {loading && <LinearProgress />}
      <Grid
        container
        direction="column"
        justify="flex-start"
        alignItems="stretch"
      >
        <Grid item>
          <Typography variant="h4">
            Alertas de restarts{" "}
            <IconButton onClick={() => getAlertRestart()}>
              <ReplayIcon />
            </IconButton>
          </Typography>
        </Grid>
        <Grid item>
          {restarts.length === 0 && (
            <Paper className={classes.noInfo}>
              <Typography className={classes.alertText} variant="body1">
                Sem alertas
              </Typography>
            </Paper>
          )}
          <Grid container>
            {restarts?.map((val, index) => (
              <Grid
                item
                key={index}
                style={{ width: "100%", marginBottom: 10 }}
              >
                <Paper className={classes.alert}>
                  <Typography className={classes.alertText} variant="body1">
                    {val?.park?.name} - {val?.board?.operationType} -{" "}
                    <b>{val?.board?.mac}</b> - <b>{val?.board?.name}</b>{" "}
                    <b>
                      {val?.type
                        ? val?.type === "restart"
                          ? "reiniciou"
                          : "reconectou"
                        : "-"}{" "}
                    </b>
                    às{" "}
                    <b>{moment(val?.createdAt).format("DD/MM/YYYY HH:mm")}</b>
                  </Typography>
                  <IconButton
                    color="primary"
                    onClick={() => expireAlert(val._id)}
                  >
                    <CloseIcon color="primary" />
                  </IconButton>
                </Paper>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}
