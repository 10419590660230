import { makeStyles, createStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) =>
  createStyles({
    marker: {
      position: "absolute",
      top: "50%",
      left: "50%",
      width: "18px",
      height: "18px",
    },
    markerDiv: {},
  })
);

export default useStyles;
