import React, { useState, useEffect, useMemo } from "react";
import useStyles, { selectStyles } from "./styles";
import axios from "config/axios";

import CloudDownload from "@material-ui/icons/CloudDownload";
import ErrorIcon from "@material-ui/icons/Error";
import ReceiptIcon from "@material-ui/icons/Receipt";
import ListIcon from "@material-ui/icons/List";

import {
  Grid,
  Button,
  Paper,
  CircularProgress,
  Typography,
  Icon,
  IconButton,
} from "@material-ui/core";

import download from "downloadjs";

import Snackbar from "components/Snackbar/Snackbar.js";
import Select from "react-select";

import { KeyboardDatePicker } from "@material-ui/pickers";

function Report() {
  const classes = useStyles();
  const [minDate, setMinDate] = useState(null),
    [maxDate, setMaxDate] = useState(null),
    [loading, setLoading] = useState(false),
    [parks, setParks] = useState([]),
    [selectedPark, setSelectedPark] = useState(),
    [alert, setAlert] = useState(""),
    [parkData, setParkData] = useState(),
    [alertSuccess, setAlertSuccess] = useState("");

  /* const generateReport = async () => {
    if (new Date(minDate) > new Date(maxDate)) {
      return setAlert("Data mínima maior que a data máxima");
    }
    setLoading((oldState) => !oldState);
    axios
      .post(
        "/park/report/generate",
        {
          minDate,
          maxDate,
          timezone: new Date().getTimezoneOffset(),
        },
        {
          responseType: "blob",
        }
      )
      .then((val) => {
        setLoading((oldState) => !oldState);
        let { data } = val;
        download(data, `relatório-${new Date().getTime()}`, "application/pdf");
      })
      .catch((error) => {
        setLoading((oldState) => !oldState);
        console.log(error);
        switch (error?.response?.data?.status) {
          default:
            setAlert("Problema ao gerar códigos, tente novamente");
            break;
        }
      });
  }; */

  const getParks = async () => {
    setLoading((oldState) => !oldState);
    axios
      .get("/admin/parks")
      .then(({ data }) => {
        setLoading((oldState) => !oldState);
        const result = [];
        data.forEach((e) => {
          result.push({
            value: e._id,
            label: e.name,
          });
        });
        setParks(result);
      })
      .catch((error) => {
        setLoading((oldState) => !oldState);
        console.error(error);
        switch (error?.response?.data?.status) {
          default:
            setAlert("Problema ao trazer estacionamento");
            break;
        }
      });
  };

  useEffect(() => {
    getParks();
  }, []);

  const getParkPayments = async () => {
    setLoading((oldState) => !oldState);
    if (new Date(minDate) > new Date(maxDate)) {
      return setAlert("Data mínima maior que a data máxima");
    }
    if (!selectedPark) {
      return setAlert("Selecione um estacionamento");
    }
    axios
      .get(
        `/admin/park/${selectedPark.value}/payments?minDate=${minDate}&maxDate=${maxDate}`
      )
      .then(({ data }) => {
        setLoading((oldState) => !oldState);
        setParkData(data);
      })
      .catch((error) => {
        setLoading((oldState) => !oldState);
        console.error(error);
        switch (error?.response?.data?.status) {
          default:
            setAlert("Problema ao trazer dados do estacionamento");
            break;
        }
      });
  };

  const generatePaymentReport = async () => {
    setLoading((oldState) => !oldState);
    if (new Date(minDate) > new Date(maxDate)) {
      return setAlert("Data mínima maior que a data máxima");
    }
    if (!selectedPark) {
      return setAlert("Selecione um estacionamento");
    }
    axios
      .post(
        "/admin/park/generate/payments/report",
        {
          parkId: selectedPark.value,
          minDate,
          maxDate,
          timezone: new Date().getTimezoneOffset(),
        },
        {
          responseType: "blob",
        }
      )
      .then((val) => {
        setLoading((oldState) => !oldState);
        let { data } = val;
        download(
          data,
          `relatório-${new Date().getTime()}-${selectedPark.label}`,
          "application/pdf"
        );
      })
      .catch((error) => {
        setLoading((oldState) => !oldState);
        console.log(error);
        switch (error?.response?.data?.status) {
          default:
            setAlert("Problema ao gerar relatório, tente novamente");
            break;
        }
      });
  };

  const calculateTotal = useMemo(() => {
    const total =
      parkData?.userPayments?.card[0]?.sumPrice ||
      0 + parkData?.userPayments?.in_app_credit[0]?.sumPrice ||
      0 + parkData?.monthlyPayments?.card[0]?.sumPrice ||
      0 + parkData?.monthlyPayments?.in_app_credit[0]?.sumPrice ||
      0;
    return total;
  }, [parkData]);

  return (
    <div>
      <Snackbar
        place="tc"
        color="danger"
        icon={ErrorIcon}
        message={alert}
        open={alert ? true : false}
        closeNotification={() => setAlert("")}
        close
      />
      <Snackbar
        place="tc"
        color="success"
        icon={ErrorIcon}
        message={alertSuccess}
        open={alertSuccess ? true : false}
        closeNotification={() => setAlertSuccess("")}
        close
      />
      <Paper className={classes.paper}>
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="center"
          spacing={3}
        >
          <Grid item style={{ marginTop: 8 }}>
            <Icon>
              <ReceiptIcon />
            </Icon>
          </Grid>
          <Grid item>
            <Typography variant="h5">Trazer dados</Typography>
          </Grid>
        </Grid>
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="center"
          spacing={3}
        >
          <Grid item>
            <KeyboardDatePicker
              margin="normal"
              id="filterMinDate"
              label="Data mínima"
              required
              format="dd/MM/yyyy"
              value={minDate}
              invalidDateMessage="Data inválida"
              maxDate={new Date()}
              maxDateMessage="Não é possível ser uma data futura"
              onChange={(date) =>
                setMinDate(() => {
                  try {
                    date = new Date(date.setHours(0));
                    date = new Date(date.setMinutes(1));
                    return date.getTime();
                  } catch (err) {
                    return date;
                  }
                })
              }
              KeyboardButtonProps={{
                "aria-label": "Mudar de vencimento",
              }}
            />
          </Grid>
          <Grid item>
            <KeyboardDatePicker
              margin="normal"
              id="filterMaxDate"
              label="Data máxima"
              required
              format="dd/MM/yyyy"
              value={maxDate}
              maxDate={new Date()}
              maxDateMessage="Não é possível ser uma data futura"
              invalidDateMessage="Data inválida"
              onChange={(date) =>
                setMaxDate(() => {
                  try {
                    date = new Date(date.setHours(23));
                    date = new Date(date.setMinutes(59));
                    return date.getTime();
                  } catch (err) {
                    return date;
                  }
                })
              }
              KeyboardButtonProps={{
                "aria-label": "Mudar de vencimento",
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <Select
              options={parks}
              onChange={(e) => setSelectedPark(e)}
              placeholder="Selecione um estacionamento"
              styles={selectStyles}
            />
          </Grid>
          <Grid item>
            <div className={classes.wrapper}>
              <Button
                onClick={getParkPayments}
                variant="contained"
                color="secondary"
                disabled={loading}
              >
                Trazer dados
              </Button>
              {loading && (
                <CircularProgress
                  size={24}
                  className={classes.buttonProgress}
                />
              )}
            </div>
          </Grid>
        </Grid>
      </Paper>
      {parkData && (
        <Paper className={classes.paper} style={{ marginTop: "5%" }}>
          <Grid
            container
            direction="row"
            justify="flex-start"
            alignItems="center"
            spacing={3}
          >
            <Grid item style={{ marginTop: 8 }}>
              <Icon>
                <ListIcon />
              </Icon>
            </Grid>
            <Grid item>
              <Typography variant="h5">{parkData?.park?.name}</Typography>
            </Grid>
            <Grid item>
              <IconButton onClick={generatePaymentReport} disabled={loading}>
                <CloudDownload />
              </IconButton>
            </Grid>
          </Grid>
          <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            spacing={3}
          >
            <Grid item>
              <Typography variant="h5">Individual {"=>"}</Typography>
            </Grid>
            <Grid item>
              <Typography variant="body1">
                Cartão:{" "}
                <b>
                  {new Intl.NumberFormat("pt-BR", {
                    style: "currency",
                    currency: "BRL",
                  }).format(parkData?.userPayments?.card[0]?.sumPrice || 0)}
                </b>
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="body1">
                Crédito pelo app:{" "}
                <b>
                  {new Intl.NumberFormat("pt-BR", {
                    style: "currency",
                    currency: "BRL",
                  }).format(
                    parkData?.userPayments?.in_app_credit[0]?.sumPrice || 0
                  )}
                </b>
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="body1">
                Dinheiro:{" "}
                <b>
                  {new Intl.NumberFormat("pt-BR", {
                    style: "currency",
                    currency: "BRL",
                  }).format(parkData?.userPayments?.money[0]?.sumPrice || 0)}
                </b>
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            spacing={3}
          >
            <Grid item>
              <Typography variant="h5">Mensalistas {"=>"}</Typography>
            </Grid>
            <Grid item>
              <Typography variant="body1">
                Cartão:{" "}
                <b>
                  {new Intl.NumberFormat("pt-BR", {
                    style: "currency",
                    currency: "BRL",
                  }).format(parkData?.monthlyPayments?.card[0]?.sumPrice || 0)}
                </b>
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="body1">
                Crédito pelo app:{" "}
                <b>
                  {new Intl.NumberFormat("pt-BR", {
                    style: "currency",
                    currency: "BRL",
                  }).format(
                    parkData?.monthlyPayments?.in_app_credit[0]?.sumPrice || 0
                  )}
                </b>
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="body1">
                Dinheiro: <b>RS --</b>
              </Typography>
            </Grid>
          </Grid>

          <Typography
            variant="h5"
            align="center"
            style={{ marginTop: "5%", fontSize: "1.5vw" }}
          >
            Total:{" "}
            {new Intl.NumberFormat("pt-BR", {
              style: "currency",
              currency: "BRL",
            }).format(calculateTotal || 0)}{" "}
          </Typography>
          <Typography variant="h6" align="center">
            Total menos {(parkData?.park?.payment_perc * 100).toFixed(2)}%:{" "}
            <b>
              {new Intl.NumberFormat("pt-BR", {
                style: "currency",
                currency: "BRL",
              }).format(
                calculateTotal *
                  parseFloat((1 - parkData?.park?.payment_perc).toFixed(2)) || 0
              )}
            </b>
          </Typography>
        </Paper>
      )}
    </div>
  );
}

export default Report;
