import { makeStyles, createStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() =>
  createStyles({
    center: {
      textAlign: "center",
      justifyContent: "center",
    },
    alert: {
      borderColor: "red",
      borderWidth: 2,
      borderStyle: "solid",
      flex: 1,
      width: "99%",
      padding: 10,
      flexDirection: "row",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
    alertText: {
      fontSize: "1.4vw",
    },
    noInfo: {
      padding: 10,
      justifyContent: "center",
      alignItems: "center",
      textAlign: "center",
    },
  })
);
export default useStyles;
