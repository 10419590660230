import React, { useState } from "react";
import {
  Grid,
  LinearProgress,
  Button,
  Card,
  CardContent,
  FormControl,
  Input,
  InputLabel,
  FormGroup,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import axios from "config/axios";

import ErrorIcon from "@material-ui/icons/Error";

import image from "assets/img/bg.webp";

//custom components
import Snackbar from "components/Snackbar/Snackbar.js";

import useStyles from "./styles";

function Login() {
  let [loading, setLoading] = useState(false),
    [email, setEmail] = useState(""),
    [alert, setAlert] = useState({
      alert: false,
      msg: "",
    }),
    [password, setPassword] = useState("");

  let history = useHistory(),
    classes = useStyles();

  const verify = async (e) => {
    e.preventDefault();
    setLoading(true);
    //console.log(email, password);
    axios
      .post("/admin/login", {
        email,
        password,
      })
      .then(({ data }) => {
        setLoading(false);
        sessionStorage.setItem("@ZigparkPark:token", data.token);
        history.push("/admin/dashboard");
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
        switch (error?.response?.status) {
          case 400:
            setAlert({
              alert: true,
              msg: "Senha inválida",
            });
            break;
          case 404:
            setAlert({
              alert: true,
              msg: "Usuário não encontrado",
            });
            break;
          default:
            setAlert({
              alert: true,
              msg: "Problema ao entrar, tente novamente",
            });
            break;
        }
      });
  };

  return (
    <div
      className={classes.backgroundImage}
      style={{
        backgroundImage: "url(" + image + ")",
        backgroundSize: "cover",
        backgroundPosition: "top center",
      }}
    >
      <div>
        {loading ? <LinearProgress color="secondary" /> : <></>}
        <Snackbar
          place="tc"
          color="danger"
          icon={ErrorIcon}
          message={alert.msg}
          open={alert.alert}
          closeNotification={() => setAlert({ alert: false, msg: "" })}
          close
        />
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="flex-start"
          className={classes.logos}
        >
          <Grid item className={classes.gridLogos}>
            <a
              href="https://park.lockyt.com.br"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={require("assets/img/zigpark.webp")}
                alt="Logomarca park lockyt"
                className={classes.logoLockyt}
              />
            </a>
          </Grid>
        </Grid>
        <Grid container direction="row" justify="center" alignItems="center">
          <Grid item>
            <Card className={classes.root}>
              <CardContent>
                <form onSubmit={verify}>
                  <FormGroup>
                    <FormControl className={classes.input}>
                      <InputLabel htmlFor="login">Login de acesso </InputLabel>
                      <Input
                        id="login"
                        type="text"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </FormControl>
                    <FormControl className={classes.input}>
                      <InputLabel htmlFor="password">Senha </InputLabel>
                      <Input
                        id="password"
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                      />
                    </FormControl>
                    <Button size="small" className={classes.btn} type="submit">
                      Entrar
                    </Button>
                  </FormGroup>
                </form>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </div>
      {/*<Footer />*/}
    </div>
  );
}

export default Login;
