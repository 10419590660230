/* eslint-disable react/display-name */
import React, { useState, useEffect, useCallback } from "react";

// @material-ui/icons
import ErrorIcon from "@material-ui/icons/Error";
import InfoIcon from "@material-ui/icons/Info";
import CloseIcon from "@material-ui/icons/Close";

import {
  Typography,
  LinearProgress,
  Button,
  Paper,
  IconButton,
  Grid,
} from "@material-ui/core";
import GoogleMapReact from "google-map-react";
import moment from "moment";

import MaterialTable from "material-table";

//Custom components
import Snackbar from "components/Snackbar/Snackbar.js";
import Marker from "components/Marker/Marker";

import axios from "config/axios";

import useStyles from "./styles";

function Boards() {
  const [data, setData] = useState(),
    [loading, setLoading] = useState(false),
    [alert, setAlert] = useState({
      alert: false,
      msg: "",
    }),
    [sensorData, setSensorData] = useState(),
    [alertSuccess, setAlertSuccess] = useState({
      alert: false,
      msg: "",
    });

  const classes = useStyles();

  const getData = useCallback(async () => {
    setLoading((oldState) => !oldState);
    axios
      .get("/admin/check/boards")
      .then(({ data }) => {
        setLoading((oldState) => !oldState);
        console.log(data);
        setData(data);
      })
      .catch((error) => {
        setLoading((oldState) => !oldState);
        console.log(error);
        switch (error?.response?.data?.status) {
          case 400:
            break;
          default:
            setAlert({
              alert: true,
              msg: "Problema ao carregar",
            });
            break;
        }
      });
  }, []);

  useEffect(() => {
    getData();
  }, [getData]);

  const getLastState = async (boardId, mac) => {
    setLoading((oldState) => !oldState);
    axios
      .get(`/admin/check/board/${boardId}/sensors`)
      .then(({ data }) => {
        setLoading((oldState) => !oldState);
        setAlert({
          alert: false,
          msg: "",
        });
        setSensorData({ data, mac });
      })
      .catch((error) => {
        setLoading((oldState) => !oldState);
        console.log(error);
        switch (error?.response?.data?.status) {
          case 400:
            break;
          default:
            setAlert({
              alert: true,
              msg: "Problema ao carregar",
            });
            break;
        }
      });
  };

  const getStatus = async () => {
    axios
      .get("/admin/get/status")
      .then(() => {
        setTimeout(() => {
          getData();
        }, 300);
        setAlertSuccess({
          alert: true,
          msg: "Get status enviado!",
        });
      })
      .catch((error) => {
        console.log(error);
        setAlert({
          alert: true,
          msg: "Problema ao carregar",
        });
      });
  };

  return (
    <div>
      <Snackbar
        place="tc"
        color="danger"
        icon={ErrorIcon}
        message={alert.msg}
        open={alert.alert}
        closeNotification={() =>
          setAlert({ alert: false, msg: "", color: alert.color })
        }
        close
      />
      <Snackbar
        place="tc"
        color="success"
        icon={InfoIcon}
        message={alertSuccess.msg}
        open={alertSuccess.alert}
        closeNotification={() => setAlertSuccess({ alert: false, msg: "" })}
        close
      />
      {loading && <LinearProgress />}
      <Paper className={classes.header}>
        <Button
          variant="contained"
          color="primary"
          disabled={loading}
          onClick={() => {
            getStatus();
          }}
        >
          Pedir get status
        </Button>
      </Paper>
      {sensorData && (
        <Paper className={classes.sensorDataPaper}>
          <Typography variant="h5">
            Último estado da placa: {sensorData.mac}{" "}
            <IconButton
              color="primary"
              aria-label="fechar"
              onClick={() => {
                setSensorData(null);
              }}
            >
              <CloseIcon />
            </IconButton>
          </Typography>
          <table className={classes.detailInfo}>
            <thead>
              <tr>
                <th>Sensor 0</th>
                <th>Sensor 1</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className={classes.flexRow}>
                  <Typography variant="body1">
                    Entrada:{" "}
                    <b>
                      {moment(sensorData?.data?.lastSensor0?.in).format(
                        "DD/MM/YYYY HH:mm:ss"
                      )}
                    </b>
                  </Typography>
                </td>
                <td>
                  <Typography variant="body1">
                    Entrada:{" "}
                    <b>
                      {moment(sensorData?.data?.lastSensor1?.in).format(
                        "DD/MM/YYYY HH:mm:ss"
                      )}
                    </b>
                  </Typography>
                </td>
              </tr>
              <tr>
                <td>
                  {sensorData?.data?.lastSensor1?.out ? (
                    <Typography variant="body1">
                      Saída:{" "}
                      <b>
                        {moment(sensorData?.data?.lastSensor0?.out).format(
                          "DD/MM/YYYY HH:mm:ss"
                        )}
                      </b>
                    </Typography>
                  ) : (
                    <Typography
                      style={{ color: "red", fontWeight: "bold" }}
                      variant="body1"
                    >
                      Sem dados de saída
                    </Typography>
                  )}
                </td>
                <td>
                  {sensorData?.data?.lastSensor1?.out ? (
                    <Typography variant="body1">
                      Saída:{" "}
                      <b>
                        {moment(sensorData?.data?.lastSensor1?.out).format(
                          "DD/MM/YYYY HH:mm:ss"
                        )}
                      </b>
                    </Typography>
                  ) : (
                    <Typography
                      style={{ color: "red", fontWeight: "bold" }}
                      variant="body1"
                    >
                      Sem dados de saída
                    </Typography>
                  )}
                </td>
              </tr>
            </tbody>
          </table>
        </Paper>
      )}
      <MaterialTable
        options={{ exportButton: true, grouping: true }}
        columns={[
          { title: "Estacionamento", field: "park.name", defaultGroupOrder: 0 },
          { title: "Mac", field: "mac" },
          { title: "Nome", field: "name" },
          {
            title: "Tipo",
            field: "operationType",
            render: (rowData) => {
              return rowData.operationType === "out"
                ? "Saída"
                : rowData.operationType === "in"
                ? "Entrada"
                : "Entrada/Saída";
            },
          },
          { title: "Sensor 0", field: "sensorType0", hidden: true },
          { title: "Sensor 1", field: "sensorType1", hidden: true },
          { title: "Serial", field: "serial", hidden: true },
          { title: "Wifi", field: "wifi", hidden: true },
          { title: "Nome", field: "name", hidden: true },
          { title: "V.Firmware", field: "V_FW" },
          { title: "V.Dados", field: "V_DATA", hidden: true },
          { title: "Viva", field: "alive", type: "boolean" },
        ]}
        data={data}
        detailPanel={[
          {
            icon: "memory",
            tooltip: "Info da placa",
            render: (rowData) => (
              <table className={classes.detailInfo}>
                <thead>
                  <tr>
                    <th>Sensores</th>
                    <th>Serial</th>
                    <th>Wifi</th>
                    <th>Extra</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <Typography variant="body1">
                        Dt. Sensor 0:{" "}
                        <b>
                          {rowData.sensorType0 === "LOW" ? "Baixo" : "Alto"}
                        </b>
                      </Typography>
                    </td>
                    <td>
                      <Typography variant="body1">
                        <b>{rowData.serial}</b>
                      </Typography>
                    </td>
                    <td>
                      <Typography variant="body1">
                        Rede: <b>{rowData.wifi ? rowData.wifi : "-"}</b>
                      </Typography>
                    </td>
                    <td>
                      <Typography variant="body1">
                        V. Dados: <b>{rowData.V_DATA}</b>
                      </Typography>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <Typography variant="body1">
                        Dt. Sensor 1:{" "}
                        <b>
                          {rowData.sensorType1 === "LOW" ? "Baixo" : "Alto"}
                        </b>
                      </Typography>
                    </td>
                    <td></td>
                    <td>
                      <Typography variant="body1">
                        RSSI:{" "}
                        <b>
                          {rowData?.lastAlive?.WiFi_RSSI
                            ? rowData?.lastAlive?.WiFi_RSSI
                            : "-"}
                        </b>
                      </Typography>
                    </td>
                    <td>
                      <Typography variant="body1">
                        Cont. seg.:{" "}
                        <b>
                          {rowData?.lastAlive?.Conta_seg
                            ? rowData?.lastAlive?.Conta_seg
                            : "-"}
                        </b>
                      </Typography>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <Button
                        onClick={() => getLastState(rowData._id, rowData.mac)}
                        variant="contained"
                        color="primary"
                      >
                        Último estado
                      </Button>
                    </td>
                    <td></td>
                    <td></td>
                    <td>
                      <Typography variant="body1">
                        Sensor Conta 0:{" "}
                        <b>
                          {rowData?.lastAlive?.conta_alto_0
                            ? rowData?.lastAlive?.conta_alto_0
                            : "-"}
                        </b>
                      </Typography>
                    </td>
                  </tr>
                  <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>
                      <Typography variant="body1">
                        Sensor Conta 1:{" "}
                        <b>
                          {rowData?.lastAlive?.conta_alto_1
                            ? rowData?.lastAlive?.conta_alto_1
                            : "-"}
                        </b>
                      </Typography>
                    </td>
                  </tr>
                  <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>
                      <Typography variant="body1">
                        ResetReason 0:{" "}
                        <b>
                          {rowData?.lastAlive?.ResetReason
                            ? rowData?.lastAlive?.ResetReason
                            : "-"}
                        </b>
                      </Typography>
                    </td>
                  </tr>
                  <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>
                      <Typography variant="body1">
                        ResetReason 1:{" "}
                        <b>
                          {rowData?.lastAlive?.ResetReason1
                            ? rowData?.lastAlive?.ResetReason1
                            : "-"}
                        </b>
                      </Typography>
                    </td>
                  </tr>
                  <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>
                      <Typography variant="body1">
                        Timestamp:{" "}
                        <b>
                          {rowData?.lastAlive?.Timestamp
                            ? moment(rowData?.lastAlive?.Timestamp).format(
                                "DD/MM/YYYY HH:mm:ss"
                              )
                            : "-"}
                        </b>
                      </Typography>
                    </td>
                  </tr>
                </tbody>
              </table>
            ),
          },
          {
            icon: "map",
            tooltip: "Localização",
            render: (rowData) => (
              <div style={{ height: "55vh", width: "100%" }}>
                <GoogleMapReact
                  bootstrapURLKeys={{
                    key: process.env.REACT_APP_GOOGLE_MAPS_KEY,
                  }}
                  defaultCenter={{ lat: rowData.lat, lng: rowData.long }}
                  defaultZoom={11}
                >
                  <Marker
                    lat={rowData.lat}
                    lng={rowData.long}
                    text={rowData.name}
                  />
                </GoogleMapReact>
              </div>
            ),
          },
        ]}
        localization={{
          header: {
            actions: "Ações",
          },
          pagination: {
            labelDisplayedRows: "{from}-{to} de {count}",
            labelRowsSelect: "registros",
            labelRowsPerPage: "Registros por página:",
            firstAriaLabel: "Primeira",
            firstTooltip: "Primeira",
            previousAriaLabel: "Anterior",
            previousTooltip: "Anterior",
            nextAriaLabel: "Próxima",
            nextTooltip: "Próxima",
            lastAriaLabel: "Última",
            lastTooltip: "Última",
          },
          toolbar: {
            exportTitle: "Exportar",
            exportAriaLabel: "Exportar",
            exportName: "Exportar CSV",
            searchTooltip: "Pesquisar",
            searchPlaceholder: "Pesquisar",
          },
          body: {
            emptyDataSourceMessage: "Sem placas",
          },
          grouping: {
            groupedBy: "Agrupado por:",
            placeholder: "Arraste um cabeçalho para agrupar",
          },
        }}
        title="Placas"
      />
      <Paper>
        <Typography variant="h4" className={classes.resetTitle}>
          Significado ResetReason
        </Typography>
        <Grid
          container
          direction="column"
          justify="flex-start"
          alignItems="flex-start"
          spacing={1}
          className={classes.resetGrid}
        >
          <Grid item>
            <Typography variant="body1">1 - Vbat power on reset</Typography>
          </Grid>
          <Grid item>
            <Typography variant="body1">
              2 - Software reset digital core
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body1">
              3 - Legacy watch dog reset digital core
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body1">
              4 - Deep Sleep reset digital core
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body1">
              5 - Reset by SLC module, reset digital core
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body1">
              6 - Timer Group0 Watch dog reset digital core
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body1">
              7 - Timer Group1 Watch dog reset digital core
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body1">
              8 - RTC Watch dog Reset digital core
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body1">
              9 - Instrusion tested to reset CPU
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body1">10 - Time Group reset CPU</Typography>
          </Grid>
          <Grid item>
            <Typography variant="body1">11 - Software reset CPU</Typography>
          </Grid>
          <Grid item>
            <Typography variant="body1">
              12 - RTC Watch dog Reset CPU
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body1">
              13 - for APP CPU, reseted by PRO CPU
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body1">
              14 - Reset when the vdd voltage is not stable
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body1">
              15 - RTC Watch dog reset digital core and rtc module
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body1">Qualquer outro - NO_MEAN</Typography>
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
}

export default Boards;
