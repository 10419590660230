import React from "react";
import useStyles from "./styles";
import "./Marker.css";

export default function Marker() {
  const classes = useStyles();
  return (
    <div className={classes.markerDiv}>
      <img
        src={require("assets/img/markers/marker.png")}
        className="marker"
        alt="Marcador"
      />
    </div>
  );
}
