/* eslint-disable react/display-name */
import React, { useState, useEffect, useCallback } from "react";

// @material-ui/icons
import ErrorIcon from "@material-ui/icons/Error";
import InfoIcon from "@material-ui/icons/Info";
import CloseIcon from "@material-ui/icons/Close";

import {
  Typography,
  LinearProgress,
  Button,
  Paper,
  Grid,
  IconButton,
} from "@material-ui/core";
import GoogleMapReact from "google-map-react";
//import moment from "moment";

import MaterialTable from "material-table";

//Custom components
import Snackbar from "components/Snackbar/Snackbar.js";
import Marker from "components/Marker/Marker";

import axiosLockyt from "config/axiosLockyt";

import moment from "moment";

import useStyles from "./styles";

function Boards() {
  const [data, setData] = useState(),
    [loading, setLoading] = useState(false),
    [alert, setAlert] = useState({
      alert: false,
      msg: "",
    }),
    [doorData, setDoorData] = useState(),
    [alertSuccess, setAlertSuccess] = useState({
      alert: false,
      msg: "",
    });

  const classes = useStyles();

  const getData = useCallback(async () => {
    setLoading((oldState) => !oldState);
    axiosLockyt
      .get("/adm/check/boards")
      .then(({ data }) => {
        setLoading((oldState) => !oldState);
        console.log(data);
        setData(data);
      })
      .catch((error) => {
        setLoading((oldState) => !oldState);
        console.log(error);
        switch (error?.response?.data?.status) {
          case 400:
            break;
          default:
            setAlert({
              alert: true,
              msg: "Problema ao carregar",
            });
            break;
        }
      });
  }, []);

  useEffect(() => {
    getData();
  }, [getData]);

  /*  const getLastState = async (boardId, mac) => {
    setLoading((oldState) => !oldState);
    axiosLockyt
      .get(`/admin/check/board/${boardId}/sensors`)
      .then(({ data }) => {
        setLoading((oldState) => !oldState);
        setAlert({
          alert: false,
          msg: "",
        });
        setSensorData({ data, mac });
      })
      .catch((error) => {
        setLoading((oldState) => !oldState);
        console.log(error);
        switch (error?.response?.data?.status) {
          case 400:
            break;
          default:
            setAlert({
              alert: true,
              msg: "Problema ao carregar",
            });
            break;
        }
      });
  }; */

  const getStatus = async () => {
    axiosLockyt
      .get("/adm/status")
      .then(() => {
        setTimeout(() => {
          getData();
        }, 300);
        setAlertSuccess({
          alert: true,
          msg: "Get status enviado!",
        });
      })
      .catch((error) => {
        console.log(error);
        setAlert({
          alert: true,
          msg: "Problema ao carregar",
        });
      });
  };

  return (
    <div>
      <Snackbar
        place="tc"
        color="danger"
        icon={ErrorIcon}
        message={alert.msg}
        open={alert.alert}
        closeNotification={() =>
          setAlert({ alert: false, msg: "", color: alert.color })
        }
        close
      />
      <Snackbar
        place="tc"
        color="success"
        icon={InfoIcon}
        message={alertSuccess.msg}
        open={alertSuccess.alert}
        closeNotification={() => setAlertSuccess({ alert: false, msg: "" })}
        close
      />
      {loading && <LinearProgress />}
      <Paper className={classes.header}>
        <Button
          variant="contained"
          color="primary"
          disabled={loading}
          onClick={() => {
            getStatus();
          }}
        >
          Pedir get status
        </Button>
      </Paper>
      {doorData && (
        <Paper className={classes.sensorDataPaper}>
          <Typography variant="h5">
            Aluguel porta: {doorData.cod}{" "}
            <IconButton
              color="primary"
              aria-label="fechar"
              onClick={() => {
                setDoorData(null);
              }}
            >
              <CloseIcon />
            </IconButton>
          </Typography>
          <Grid
            container
            direction="row"
            justify="flex-start"
            alignItems="center"
            spacing={5}
          >
            <Grid item>
              <Typography variant="body1">
                Usuário:{" "}
                <b>{doorData?.user?.name ? doorData?.user?.name : "-"}</b>
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="body1">
                Encomenda?: <b>{doorData.delivery ? "Sim" : "Não"}</b>
              </Typography>
            </Grid>
          </Grid>
        </Paper>
      )}
      <MaterialTable
        options={{ exportButton: true, grouping: true }}
        columns={[
          {
            title: "Nome",
            field: "name",
            render: (rowData) => {
              return rowData.name ? rowData.name : "-";
            },
          },
          {
            title: "Info",
            field: "info",
            render: (rowData) => {
              return rowData.info ? rowData.info : "-";
            },
          },
          { title: "Mac", field: "mac" },
          { title: "Serial", field: "serial", hidden: true },
          { title: "Wifi", field: "wifi", hidden: true },
          { title: "V.Firmware", field: "V_FW" },
          { title: "V.Dados", field: "V_DATA", hidden: true },
          { title: "Viva", field: "alive", type: "boolean" },
        ]}
        data={data}
        detailPanel={[
          {
            icon: "sensor_door",
            tooltip: "Info aluguéis",
            render: (rowData) => (
              <Grid
                container
                direction="row"
                justify="space-around"
                alignItems="center"
                spacing={5}
              >
                {rowData.doors.map((e, i) => (
                  <Grid item key={i}>
                    <Button
                      variant="outlined"
                      disabled={!e.rented}
                      onClick={() => {
                        setDoorData(e);
                      }}
                      style={
                        e.active
                          ? e.rented
                            ? { borderColor: "red", borderWidth: 4 }
                            : { borderColor: "green", borderWidth: 4 }
                          : { borderColor: "grey" }
                      }
                    >
                      {e?.cod}
                    </Button>
                  </Grid>
                ))}
              </Grid>
            ),
          },
          {
            icon: "memory",
            tooltip: "Info da placa",
            render: (rowData) => (
              <table className={classes.detailInfo}>
                <thead>
                  <tr>
                    <th>Portas</th>
                    <th>Serial</th>
                    <th>Wifi</th>
                    <th>Extra</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      {rowData?.lastAlive?.doorsState.length > 0
                        ? rowData?.lastAlive?.doorsState.map((e, i) => (
                            <Typography variant="body1" key={i}>
                              <b>{e}</b>
                            </Typography>
                          ))
                        : "-"}
                    </td>
                    <td>
                      <Typography variant="body1">
                        <b>{rowData.serial}</b>
                      </Typography>
                    </td>
                    <td>
                      <Typography variant="body1">
                        Rede: <b>{rowData.wifi ? rowData.wifi : "-"}</b>
                      </Typography>
                    </td>
                    <td>
                      <Typography variant="body1">
                        V. Dados: <b>{rowData.V_DATA}</b>
                      </Typography>
                    </td>
                  </tr>
                  <tr>
                    <td></td>
                    <td></td>
                    <td>
                      <Typography variant="body1">
                        RSSI:{" "}
                        <b>
                          {rowData?.lastAlive?.WiFi_RSSI
                            ? rowData?.lastAlive?.WiFi_RSSI
                            : "-"}
                        </b>
                      </Typography>
                    </td>
                    <td>
                      <Typography variant="body1">
                        Conta_seg:{" "}
                        <b>
                          {rowData?.lastAlive?.Conta_seg
                            ? rowData?.lastAlive?.Conta_seg
                            : "-"}
                        </b>
                      </Typography>
                    </td>
                  </tr>
                  <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>
                      <Typography variant="body1">
                        Timestamp:{" "}
                        <b>
                          {rowData?.lastAlive?.Timestamp
                            ? moment(rowData?.lastAlive?.Timestamp).format(
                                "DD/MM/YYYY HH:mm:ss"
                              )
                            : "-"}
                        </b>
                      </Typography>
                    </td>
                  </tr>
                </tbody>
              </table>
            ),
          },
          {
            icon: "map",
            tooltip: "Localização",
            render: (rowData) => (
              <div style={{ height: "55vh", width: "100%" }}>
                <GoogleMapReact
                  bootstrapURLKeys={{
                    key: process.env.REACT_APP_GOOGLE_MAPS_KEY,
                  }}
                  defaultCenter={{ lat: rowData.lat, lng: rowData.long }}
                  defaultZoom={11}
                >
                  <Marker
                    lat={rowData.lat}
                    lng={rowData.long}
                    text={rowData.name}
                  />
                </GoogleMapReact>
              </div>
            ),
          },
        ]}
        localization={{
          header: {
            actions: "Ações",
          },
          pagination: {
            labelDisplayedRows: "{from}-{to} de {count}",
            labelRowsSelect: "registros",
            labelRowsPerPage: "Registros por página:",
            firstAriaLabel: "Primeira",
            firstTooltip: "Primeira",
            previousAriaLabel: "Anterior",
            previousTooltip: "Anterior",
            nextAriaLabel: "Próxima",
            nextTooltip: "Próxima",
            lastAriaLabel: "Última",
            lastTooltip: "Última",
          },
          toolbar: {
            exportTitle: "Exportar",
            exportAriaLabel: "Exportar",
            exportName: "Exportar CSV",
            searchTooltip: "Pesquisar",
            searchPlaceholder: "Pesquisar",
          },
          body: {
            emptyDataSourceMessage: "Sem placas",
          },
          grouping: {
            groupedBy: "Agrupado por:",
            placeholder: "Arraste um cabeçalho para agrupar",
          },
        }}
        title="Placas"
      />
    </div>
  );
}

export default Boards;
