import { makeStyles, createStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      padding: 10,
    },
    detailInfo: {
      padding: "3%",
      flex: 1,
      width: "100%",
    },
    sensorDataPaper: {
      padding: 10,
      marginBottom: "2%",
    },
    flexRow: {
      flexDirection: "row",
    },
    resetGrid: {
      margin: 5,
    },
    resetTitle: {
      margin: 10,
      paddingTop: 10,
    },
    header: {
      display: "flex",
      justifyContent: "center",
      flexDirection: "row",
      padding: 10,
      marginBottom: 10,
    },
  })
);
export default useStyles;
